<template>
  <path
    class="cls-2 haba--1"
    d="M1700.51,154.69c7.07,33.17,26.27,63.89,54.2,83.25,43.59,30.23,106.52,36.2,133.91,81.62,19.75,32.76,13.21,74.71,23.91,111.44,30.6,105.05,169.49,110.92,227.18,26.95,25.9-37.69,32.67-87.17,20.77-131.32-8.85-32.85-27.18-62.5-36.4-95.25-6.26-22.23-8.19-45.68-5.65-68.64,3.78-34.17,17.21-69.19,6.48-101.85-10.98-33.42-45.69-54.7-80.55-59.39-83.45-11.22-160.72,44.15-245.86,38.89-20.33-1.26-41.77-4.32-60.24,4.25-20.32,9.43-32.34,31.18-37.47,52.99-4.4,18.72-4.29,38.28-.29,57.06Z"
  />
  <path
    class="cls-2 haba--2"
    d="M242.3,871.39c17.57,7.91,33.8,19.11,47.98,32.2,41.84,38.62,70.32,95.31,123.59,115.41,59.92,22.61,125.27-10.28,189.17-14.46,86.81-5.67,260.89,84.36,185.35,193.01-11.3,16.25-28.45,27.74-46.75,35.27-48.85,20.11-104.52,13.55-155.73,.57-51.21-12.98-101.33-32.09-153.98-36.42-60.22-4.95-120.29,9.74-178.04,27.53-46.26,14.25-96.85,30.47-141.6,12.03-31.32-12.91-54.59-42.13-63.48-74.81-8.89-32.69-4.21-68.25,9.67-99.16,10.18-22.67,25.17-43.3,32.01-67.2,7.52-26.28,4.61-54.57,11.15-81.13,10.73-43.58,58.79-59.35,100-54.4,14.09,1.69,27.74,5.72,40.66,11.54Z"
  />
  <path
    class="cls-2 haba--2"
    d="M1666.9,1084.52c-36.75,38.8-41.93,81.59-41.56,132.56,.28,39.52,33.66,80.09,75.7,74.27,20.07-2.78,37.81-14.15,54.4-25.78,16.59-11.64,33.27-24.06,52.79-29.51,31.48-8.79,68.83,.89,95.01-18.69,22.95-17.17,27.12-50.63,20.58-78.54-11.59-49.42-52.14-90.89-101.28-103.59-55.27-14.28-116.38,7.84-155.63,49.28Z"
  />

  <path
    class="cls-3 haba_borde--1"
    d="M1655.5,122.7c9.92,27.77,34.84,47.82,61.61,60.2,20.07,9.28,41.8,15.13,60.65,26.7,28.54,17.52,48.66,48.16,53.4,81.31,2.82,19.71,.45,39.82,2.15,59.66,2.57,30.08,15.63,60.4,40.06,78.14,16.27,11.82,36.36,17.33,56.34,19.59,55.76,6.3,117.75-15.47,145.14-64.45,24.32-43.48,17.18-97.39,5.12-145.73-12.06-48.34-28.49-97.71-20.48-146.89,2.72-16.7,8.19-34.12,2.57-50.08-7.76-22.05-34.28-32.25-57.6-30.59-23.32,1.66-44.97,12.06-67.34,18.85-33.12,10.06-68.6,12.23-102.7,6.28-29.24-5.1-57.45-16.05-87.04-18.37-57.59-4.51-114.12,43.08-91.87,105.38Z"
  />
  <path
    class="cls-3 haba_borde--2"
    d="M252.98,896.3c42.1-9.63,88.67,3.24,119.85,33.11,40.29,38.6,54.45,101.1,100.82,132.13,32.99,22.08,75.87,23.29,115.02,16.68,39.14-6.61,76.96-20.19,116.34-25.16,39.39-4.97,82.62,.03,112.59,26.06,18.34,15.93,30.14,38.65,34.92,62.46,2.97,14.81,3.26,30.66-2.64,44.56-6.97,16.43-21.71,28.3-37.19,37.2-57.81,33.26-131.6,32.67-192.87,6.3-28.03-12.06-54.01-29.16-83.32-37.64-95.36-27.61-196.09,41.6-293.83,24.23-20.89-3.71-41.63-11.76-56.7-26.69-16.78-16.62-24.93-40.03-29.92-63.11-18.93-87.5-7.56-206.24,96.93-230.13Z"
  />
  <path
    class="cls-2 habita"
    d="M832.51,981.68c.74,.47,1.54,.84,2.33,1.09,2.31,.74,5.63,.32,7.55-1.23,3.27-2.65,4.47-8.5,1.17-11.58-3.63-3.38-12.52-4.93-14.38,.95-.65,2.04-.91,4.36-.25,6.44,.57,1.79,1.95,3.3,3.59,4.34Z"
  />
  <path
    class="cls-2 habita"
    d="M309.37,327.55c-1.2-.14-2.4-.12-3.53,.04-3.29,.46-7.13,2.95-8.56,6.01-2.46,5.21-.46,13.13,5.45,14.96,6.48,2.01,18.36-1.37,17.16-9.72-.42-2.9-1.46-5.91-3.51-8.08-1.77-1.87-4.37-2.91-7-3.21Z"
  />
  <path
    class="cls-2 habita"
    d="M1424.12,173.86c-1.08-.12-2.17-.11-3.19,.03-2.97,.42-6.44,2.67-7.74,5.43-2.22,4.71-.42,11.87,4.92,13.53,5.86,1.82,16.6-1.24,15.51-8.79-.38-2.63-1.32-5.35-3.18-7.3-1.6-1.69-3.95-2.63-6.33-2.9Z"
  />
  <path
    class="cls-2 habita"
    d="M506.01,82.26c-2.47,.56-4.85,1.42-6.98,2.52-6.21,3.2-12.11,10.81-12.85,17.88-1.26,12.07,8.2,26.42,21.21,25.96,14.28-.51,35.54-15.46,27.35-31.23-2.84-5.48-7.02-10.74-12.59-13.62-4.81-2.49-10.7-2.75-16.14-1.52Z"
  />
  <path
    class="cls-2 habita"
    d="M1870.15,738.19c-2.12,.48-4.15,1.22-5.99,2.16-5.33,2.74-10.38,9.27-11.02,15.33-1.08,10.34,7.03,22.65,18.18,22.25,12.24-.43,30.46-13.25,23.45-26.77-2.44-4.7-6.01-9.2-10.79-11.67-4.12-2.13-9.18-2.35-13.83-1.3Z"
  />
</template>
<script>
export default {
  name: "svg habas",
};
</script>